import { useTranslation } from "next-i18next";

import ManagerList from "../components/ManagerList";

import { ShipdaService } from "../../..";
import MANAGER_RECORD from "../constants/MANAGER_RECORD";
import Styled from "./index.styles";

export default function NewcomerGuide({
  serviceType,
}: {
  serviceType: ShipdaService;
}) {
  const { t } = useTranslation(["common-new"]);

  return (
    <Styled.newcomerGuide>
      <Styled.title>[{t("담당자별연락처_신규상담")}]</Styled.title>

      <Styled.newcomerGuideManager>
        <ManagerList
          managerList={MANAGER_RECORD[serviceType]["newcomerGuide"]}
        />
      </Styled.newcomerGuideManager>
    </Styled.newcomerGuide>
  );
}
